import { CCApiService } from './api-service';
import { Capability, Customer, UIConfig, ClientCredentials } from './interfaces';

export class CustomersService {
  static ApiService: CCApiService;
  static customersAPI: string;
  static apiBasePath: string;

  static init(config: UIConfig) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.customersAPI = `${this.apiBasePath}/customers`;
  }

  static async getCustomers(): Promise<Customer[]> {
    return this.ApiService.performFetch(`${this.customersAPI}?size=200`).then(customers => {
      return customers.content;
    });
  }

  static async getCustomer(customerId: string): Promise<Customer> {
    return this.ApiService.performFetch(`${this.customersAPI}/${customerId}`).then(customer => {
      return customer;
    });
  }

  static async getCustomerCredentials(customerId: string): Promise<ClientCredentials> {
    return this.ApiService.performFetch(`${this.customersAPI}/${customerId}/credentials`).then(credentials => {
      return credentials;
    });
  }

  static async getCustomerCapabilities(): Promise<Capability[]> {
    return this.ApiService.performFetch(`${this.apiBasePath}/capabilities`).then(capabilities => {
      return capabilities;
    });
  }

  static async getCustomerIntegrations(filter: {}, customerId): Promise<any> {
    return this.ApiService.performFetch(`${this.customersAPI}/${customerId}/integrations?${new URLSearchParams(filter)}`).then(capabilities => {
      return capabilities;
    });
  }

  static async getCustomerIntegrationCredentials(integrationType: string, id: string): Promise<ClientCredentials> {
    return this.ApiService.performFetch(`${this.apiBasePath}/integrations/${integrationType}/${id}/credentials`).then(capabilities => {
      return capabilities;
    });
  }
}
